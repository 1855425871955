<template>
  <v-container>
    <v-card>
      <v-card-title class="accent text-h5 font-weight-bold pa-3">
        <v-icon color="primary" left>
          mdi-badge-account-horizontal-outline
        </v-icon>
        Cargos
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-card flat>
          <v-card-title class="">
            <v-text-field
              style="max-width: 400px"
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              clearable
              dense
              hide-details
            ></v-text-field>

            <v-spacer></v-spacer>
            <SincronizarJornadas />

            <v-btn
              @click="openDialogAddCargo"
              color="secondary"
              class="white--text"
            >
              adicionar
            </v-btn>
          </v-card-title>

          <v-divider></v-divider>
          <v-card-text>
            <v-data-table
              v-if="cargos"
              :headers="headers"
              :search="search"
              :items="cargos"
              :items-per-page="15"
              class="data-tables data-tables__row-click"
              @click:row="goToCargo"
            >
              <template v-slot:item.status="{ item }">
                {{ item.status | status }}
              </template>

              <template v-slot:item.jornada_trabalho="{ item }">
                <div v-if="item.jornada_trabalho" class="d-flex align-center">
                  <v-icon left color="success">mdi-checkbox-marked</v-icon>
                  <span style="margin-top: 2px">CADASTRADO</span>
                </div>
                <div v-else class="d-flex align-center">
                  <v-icon left color="">mdi-checkbox-blank-outline</v-icon>
                  <span style="margin-top: 2px">EM ABERTO</span>
                </div>
              </template>

              <template v-slot:item.created_at="{ item }">
                {{ item.created_at | dateFormat("dd/MM/yyyy") }}
              </template>
            </v-data-table>
          </v-card-text>
          <NovoEditCargo
            v-if="dialogNovoEditCargo"
            :dialogNovoEditCargo.sync="dialogNovoEditCargo"
            :cargo_id="cargo_id"
            :adicionar="adicionar"
            @fetch-cargos="getCargos"
          />
        </v-card>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { fetchCargos } from "@/api/gestor/cargos.js";

export default {
  name: "Cargos",

  components: {
    NovoEditCargo: () => import("./NovoEditCargo"),
    SincronizarJornadas: () => import("./components/SincronizarJornadas.vue"),
  },

  data() {
    return {
      loading: true,
      cargos: [],
      search: "",
      adicionar: true,
      dialogNovoEditCargo: false,

      cargo_id: null,
    };
  },

  computed: {
    headers() {
      return [
        {
          text: "ID",
          value: "id",
          width: "80px",
        },
        {
          text: "Descrição",
          value: "descricao",
        },
        {
          text: "Jornada de Trabalho",
          value: "jornada_trabalho",
          // align: "center",
        },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "Data",
          value: "created_at",
        },
      ];
    },
  },

  methods: {
    goToCargo(item) {
      this.cargo_id = item.id;
      this.dialogNovoEditCargo = true;
      this.adicionar = false;
    },

    openDialogAddCargo() {
      this.cargo_id = null;
      this.adicionar = true;
      this.dialogNovoEditCargo = true;
    },

    getClientes() {
      let cargos = [{}, {}];

      this.cargos = cargos;
    },

    getCargos() {
      this.loading = true;
      fetchCargos()
        .then((response) => {
          this.cargos = response;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },

  mounted() {
    this.getCargos();
  },
};
</script>

<style lang="scss"></style>
